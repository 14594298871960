<template>
  <div>
    <keep-alive>
      <router-view
        v-if="getFirstLevelRoute($route).meta.keepAlive"
        :key="getFirstLevelRoute($route).fullPath"
      />
    </keep-alive>
    <router-view
      v-if="!getFirstLevelRoute($route).meta.keepAlive"
      :key="getFirstLevelRoute($route).fullPath"
    />
  </div>
</template>

<script>
export default {
  name: 'PlainView',
  methods: {
    getFirstLevelRoute (route) {
      if (!Array.isArray(route.matched) || route.matched.length === 0) {
        return route
      }
      return route.matched[0]
    }
  }
}
</script>
